.form-item {
    .MuiFormControl-root {
        &.MuiTextField-root {
            width: 100%;
        }
    }
}

.error-text {
    color: red;
    margin-top: 8px;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid !important;
}

// .layout-form-header {
// }

.customButton {
    height: 40px;
}

.customLabel {
    color: #3f4254;
    margin-bottom: 5px;
    font-weight: 600;
}

.customRequired {
    color: #dc3545;
}

.customInput {
    background: #ffffff;
    border: 1px solid #e9eef7;
    box-sizing: border-box;
    height: 40px;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;

    &:focus-visible {
        outline: none !important;
    }
}

.customInputHasIcon {
    .ant-input-wrapper {
        &.ant-input-group {
            input {
                border: 1px solid #e9eef7;
                background: #ffffff;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                box-sizing: border-box;
                height: 40px;
                padding-left: 12px;
                padding-right: 12px;
            }

            .ant-input-group-addon {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border: 1px solid #e9eef7;
                border-left: none;
            }
        }
    }
}

.customSelect {
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e9eef7;
    box-sizing: border-box;
    height: 40px;
    border: none !important;

    .ant-select-selector {
        height: 100% !important;
        border-radius: 8px !important;
        border: 1px solid #e9eef7 !important;

        .ant-select-selection-search > input {
            height: 100% !important;
        }

        .ant-select-selection-item {
            margin-top: 3px;
        }

        .ant-select-selection-placeholder {
            margin-top: 3px;
        }
    }
}

.customCheckbox {
    margin-top: 35px;

    @include mb-down {
        margin-top: unset;
    }
}

.customDate {
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e9eef7;
    box-sizing: border-box;
    height: 40px;
}

// .customRadio {
 
// }

.tr-left {
    text-align: left;
    vertical-align: center;
}
.tr-center {
    text-align: center;
    vertical-align: center;
}
.tr-right {
    text-align: right;
    vertical-align: center;
}

.customTableCtn {
    width: 100%;
    overflow-y: auto;
    padding-left: 15px;
    padding-right: 0;
    .customTable {
        width: 100%;
        border: 1px solid #e9eef7;
        padding-left: 16px;
        padding-right: 16px;
        border-radius: 8px;

        tr {
            width: 100%;
            cursor: pointer;

            &:hover {
                background: #f3f6f9;

                &:first-child {
                    background: #e6f8fd;
                }
            }

            th {
                padding: 10px;
                border-bottom: 1px solid #e9eef7;
                border-right: 1px solid #e9eef7;
            }

            td {
                padding: 10px;
                border-bottom: 1px solid #e9eef7;
                border-right: 1px solid #e9eef7;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
        }
    }
}

.ant-form-item {
    padding: 0 !important;
    margin: 0 !important;
}

.customModal {
    .ant-modal-content {
        .ant-modal-header {
            background: #3699ff;
            padding: 16px 20px !important;

            .modal-title {
                color: #ffffff;
                font-weight: 500;
            }
        }

        .ant-modal-body {
            padding: 16px 20px !important;
        }
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

img {
    object-fit: cover;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
    border-color: #ff4d4f !important;
}

.ant-form-item-has-error .ant-input-group-addon {
    border-color: #ff4d4f !important;
}
